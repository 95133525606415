<template>
  <!-- Table Container Card -->
  <b-card no-body class="mb-0">
    <b-overlay :show="formShow" rounded="sm" no-fade>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Entries</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
          </b-col>

          <b-col cols="12" md="4" class="d-flex align-items-left justify-content-end mb-1 mb-md-0 ">
            <!-- <v-select :options="statusOptions" v-model="statusType" class="per-page-selector d-inline-block w-100" label="text" :reduce="(val) => val.value" placeholder="Status" /> -->
            <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="statusOptions" v-model="statusType" valueFormat="object" :limit="2" class="per-page-selector d-inline-block w-100" style="position: absolute" placeholder="Status" />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="5">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1 " placeholder="Search..." />

              <b-button variant="primary" :to="{ name: 'add-adjustment' }" v-if="$Can('order_add')">
                <span class="text-nowrap">Add Adjustment</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        striped
        hover
        ref="refInvoiceListTable"
        class="position-relative"
        :style="totalRows < 3 ? 'height: 500px' : ''"
        :items="listInvoices"
        :filter="filter"
        filter-debounce="250"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        selectable
        select-mode="single"
        responsive
        :small="true"
        head-variant="dark"
        :fields="inHeaders"
        show-empty
        empty-text="No matching records found"
        @row-selected="onRowSelected"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <strong class="text-primary"> #{{ data.value }} </strong>
        </template>

        <template #cell(customerId)="data">
          <strong class="font-weight-bold"> #{{ data.value }} </strong>
        </template>

        <template #cell(userId)="data">
          <strong class="font-weight-bold"> #{{ data.value }} </strong>
        </template>

        <template #cell(vehicleId)="data">
          <strong class="font-weight-bold"> #{{ data.value }} </strong>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(date)="data">
          <span class="text-nowrap" v-if="data.value != '01 Jan 1970'">
            {{ data.value }}
          </span>

          <span v-else class="text-nowrap">
            N/A
          </span>
        </template>

        <!-- Column: Customer -->
        <template #cell(get_customer)="data">
          <span class="text-nowrap" v-if="data.value.firstName != undefined">
            {{ data.value.firstName + ' ' + data.value.lastName }}
          </span>

          <span class="text-nowrap" v-else>
            N/A
          </span>
        </template>
        <!-- Column: INVOICE total -->
        <template #cell(total)="data">
          <span class="text-nowrap"> $ {{ data.value ? formatPrice(data.value, 2) : formatPrice(0) }} </span>
        </template>

        <template #cell(totalPaid)="data">
          <span class="text-nowrap"> $ {{ data.value ? formatPrice(data.value, 2) : formatPrice(0) }} </span>
        </template>
        <template #cell(leftTotal)="data">
          <span class="text-nowrap"> $ {{ data.value ? formatPrice(data.value, 2) : formatPrice(0) }} </span>
        </template>

        <template #cell(status)="data">
          <b-badge v-if="data.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
          <b-badge v-if="data.item.status == 'B'" pill variant="primary" class="badge-glow">Sent To Manager</b-badge>
          <b-badge v-if="data.item.status == 'C'" pill variant="success" class="badge-glow">Payment Made</b-badge>
          <b-badge v-if="data.item.status == 'D'" pill variant="warning" class="badge-glow">Partial Payment</b-badge>
          <b-badge v-if="data.item.status == 'E'" pill variant="danger" class="badge-glow">Past Due</b-badge>
          <b-badge v-if="data.item.status == 'F'" pill variant="info" class="badge-glow">Approved</b-badge>
          <b-badge v-if="data.item.status == 'G'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
          <b-badge v-if="data.item.status == 'H'" pill variant="success" class="badge-glow">Completed</b-badge>
          <b-badge v-if="data.item.status == 'J'" pill variant="danger" class="badge-glow">Canceled</b-badge>

          <!-- <b-badge v-if="data.item.status == 'D'" pill variant="light-info" class="badge-glow">Incoming </b-badge>
            <b-badge v-if="data.item.status == 'I'" pill variant="light-info" class="badge-glow">In Stock Pending</b-badge> -->
        </template>

        <template #cell(type)="data">
          <b-badge v-if="data.item.type == 'A'" pill variant="success" class="badge-glow">Pack Up</b-badge>
          <b-badge v-if="data.item.type == 'B'" pill variant="primary" class="badge-glow">Pack Down</b-badge>

          <!-- <b-badge v-if="data.item.status == 'D'" pill variant="light-info" class="badge-glow">Incoming </b-badge>
            <b-badge v-if="data.item.status == 'I'" pill variant="light-info" class="badge-glow">In Stock Pending</b-badge> -->
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'adjustment-preview', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Preview</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'adjustment-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import accountingStoreModule from '../accountingStoreModule';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  components: { Treeselect, vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup },

  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    const statusOptions = [
      // { text: 'Website Status Selection', value: '' },
      { id: 'A', text: 'Draft', value: 'A' },
      { id: 'B', text: 'Sent To Manager', value: 'B' },
      { id: 'C', text: 'Payment Made', value: 'C' },
      { id: 'D', text: 'Partial Payment', value: 'D' },
      { id: 'E', text: 'Past Due', value: 'E' },
      { id: 'F', text: 'Approved', value: 'F' },
      { id: 'G', text: 'Not Approved', value: 'G' },
      { id: 'H', text: 'Completed Order', value: 'H' },
      { id: 'J', text: 'Canceled Order', value: 'J' },
    ];

    return {
      filter: null,
      filterOn: [],
      listInvoices: [],
      perPage: localStorage.getItem('invoiceShow') ?? 10,
      formShow: false,
      inHeaders: [],
      colWidthFactor: null,
      fetchInvoices: [
        // {
        //   costCode: 'ATB-FC1',
        //   dateOfInput: '01-05-2022',
        //   vendor: 'Michelin',
        //   description: 'RIMS',
        //   value: this.formatPrice(100),
        // },
      ],
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      baseURL: store.state.app.baseURL,

      statusOptions,

      statusType: null,
      status: null,
    };
  },

  created() {
    this.getItems();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('invoiceShow', val);
      },
    },

    statusType: {
      handler: function(val, before) {
        let filterObj = {
          perPage: this.perPage,
          status: val ? val : [],
        };

        localStorage.setItem('filterAdjustmentList', JSON.stringify(filterObj));

        this.listInvoices = [];
        this.fetchInvoices.forEach((element) => {
          if (val == null || val.length == 0) {
            this.listInvoices = this.fetchInvoices;
          } else {
            val.forEach((filterStatus) => {
              if (element.status == filterStatus.value) {
                this.listInvoices.push(element);
              }
            });
          }
          this.totalRows = this.listInvoices.length;
        });
      },
    },
  },

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    onRowSelected(item) {
      router.push({ name: 'adjustment-preview', params: { id: item[0].id } });
    },
    filterTreeSelect() {
      let treeOptionsStatus = [
        {
          id: 100000,
          label: 'Status',
          children: [],
        },
      ];

      this.statusOptions.forEach((element, index) => {
        let setStatus = {
          id: element.value ? element.value : index,
          label: element.text,
          value: element.value,
          is: 'status',
        };
        treeOptionsStatus[0].children.push(setStatus);
      });
      this.statusOptions = treeOptionsStatus;
    },

    getItems() {
      this.formShow = true;
      store
        .dispatch('accounting/fetchAdjustment', [])
        .then((res) => {
          this.getColumns(res.data.headers, res.data.data);

          this.totalRows = res.data.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getColumns(userheaders, data) {
      store
        .dispatch('accounting/fetchAdjustmentColumns', [])
        .then((res) => {
          this.createTable(userheaders, res.data);

          this.fetchInvoices = data;
          this.listInvoices = data;

          this.filterTreeSelect();
          this.storageAdjustmentItem();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          this.inHeaders.push({ key: items, label: columns.title, sortable: true, class: 'text-center', thStyle: width });
        });
        if (this.$Can('inventory_show') || this.$Can('inventory_edit')) {
          this.inHeaders.push({ key: 'actions', class: 'text-center', thStyle: 'width: 5%' });
        }
        this.formShow = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    storageAdjustmentItem() {
      let key = JSON.parse(localStorage.getItem('filterAdjustmentList'));
      if (key) {
        this.perPage = key.perPage ? key.perPage : 10;

        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        if (array.length > 0) {
          this.statusType = array;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
